import { All_States, Australia_States, New_Zealand_States } from "ratpack-server/src/types";
import { useState } from "react";

export const stateful = function <T extends string|number|Date>(def = '' as T, opts: {
    setter?: Function, // Specifically for extracting the value from an input event, see _setter
    validate?: Function,
    effect?: Function, // For side effects when the value is set, called when either setValue or event triggers a change
} = {}): [T, any, boolean, Function] {
    const { setter, valueKey, onChangeKey, validate, effect } = {
        valueKey: 'value',
        onChangeKey: 'onChange',
        validate: Boolean,
        effect: (v:T) => v,
        ...opts,
    };
    const [value, setValue] = useState<T>(def)
    const valid = validate(value)
    const _setter = setter ?? (event => event.target[valueKey])
    const use = {
        [valueKey]: value as T,
        [onChangeKey]: (event => {
            const value = _setter(event)
            effect(value)
            return setValue(value)
        }) as Function,
    } // as {[typeof valueKey]: T,[typeof onChangeKey]: Function}
    return [value, use, valid, (value:T) => { effect(value); return setValue(value); }]
}
export function deepCompare(a:any, b:any) {
    console.log({a,b})
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) return false;
    for (const key of aKeys) {
      if (!deepCompare(a[key], b[key])) return false;
    }
    return true;
}

// export const AllOperators = {
//     "apollo": "Apollo",
//     "barefoot-campers": "Barefoot Campers",
//     "camperman": "Camperman",
//     "cheapa": "Cheapa",
//     "hippie": "Hippie",
//     "jucy": "Jucy",
//     "letsgo": "Lets Go",
//     "spaceships": "Spaceships",
//     "star-rv": "Star RV",
//     "travellers-autobarn": "Travellers Autobarn",
// }

export const before = (from = new Date()) => (t: Date) => t.getTime() < from.getTime()

export const Sorted_States = All_States.sort((a, b) => a.key > b.key ? 1 : -1)
export const AU_States = Sorted_States.filter(({ country }) => country == 'AU')
export const NZ_States = Sorted_States.filter(({ country }) => country == 'NZ')
export const toSelectItems = (a: Array<{ key: string, nice: string }>) => a.map(({ key, nice }) => ({ value: key, label: nice, disabled: false }))
export const Spaced_States = [
    { value: 'au', label: 'Australia', disabled: true },
    ...toSelectItems(AU_States),
    { value: 'nz', label: 'New Zealand', disabled: true },
    ...toSelectItems(NZ_States),
]

export const selected = (items) => Object.entries(items).filter(([k, v]) => v).map(([k, v]) => k)

// Clean up errors common with ZD IDs
export const zdid = (zid) => (zid ?? '').replace(/\s+/gim, '').replace(/\u200B/gim, '').replace('%E2%80%8B', '')

export type ValueOf<T> = T[keyof T];

// export const toCanonicalLocationName:Record<string, (typeof All_States[0])['key']> = {
//     'adelaide': 'au.adelaide',
//     'adelaide_dropoff': 'au.adelaide',
// }

const toSlug = (s: string, delimiter:string='_') => s.replace(/\s/ig, delimiter).toLowerCase()
const ZDLocationToCanonicalLocation = Object.fromEntries([
    ...Object.keys(Australia_States).map(state=>[toSlug(state), `au.${toSlug(state)}`]),
    ...Object.keys(Australia_States).map(state=>[toSlug(state,' '), `au.${toSlug(state)}`]),
    ...Object.keys(New_Zealand_States).map(state=>[toSlug(state), `nz.${toSlug(state)}`]),
    ...Object.keys(New_Zealand_States).map(state=>[toSlug(state,' '), `nz.${toSlug(state)}`]),
])
console.log({ZDLocationToCanonicalLocation})
export const ZDLocationCanonical = (zdl:string) => ZDLocationToCanonicalLocation[zdl.replace('_dropoff','')]
